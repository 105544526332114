<template>
    <div>
     <b-modal
      centered
      id="door-handler"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      size="lg"
      @ok="handleSumbit"
      title="Dodaj zdarzenie dla samochodu"
    >
      <b-form-group label="Data serwisu">
        <b-form-datepicker
          :start-weekday="1"
          v-model="form.date"
          placeholder="Wybierz datę"
        />
      </b-form-group>
      <b-form-group label="Godzina serwisu">
        <b-input-group>
          <b-form-input
            v-model="form.time"
            type="text"
            placeholder="HH:MM:SS"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
              type="time"
              placeholder="Wybierz godzinę"
              v-model="form.time"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Wydarzenie">
        <b-form-select
          v-model="form.type"
          :options="['Otwórz', 'Zamknij']"
        >
        </b-form-select>
      </b-form-group>
    </b-modal>
    </div>
</template>


<script>
export default {
    props: ['car'],
  data() {
    return {
        form: {
            date: null,
            time: null,
            type: null,
        }
    }
  },
  methods:{
    async handleSumbit(){
        await this.$store.dispatch("addDoorHandler", {...this.form, carId: this.car.id, reg: this.car.reg})
        await this.$store.dispatch("getDoorEvents");

    }
  }

}
</script>